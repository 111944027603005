import { BillingItemSourceType } from 'app/types';
import { BillingFormData } from 'app/features/doctorAssessment/components/billingSection/forms/billingForm/billingForm';

export interface AssessmentBillingItemParams {
  isExcluded: boolean;
  preAuthorization: boolean;
  performerBillingItemId: string;
  invoicePackageItemSnapshotId?: string;
  invoicePackageSnapshotId?: string;
  quantity: number;
  onInsurance: boolean;
  locationTemplate?: string;
  locationElements?: string[];
  billingItemSourceType: BillingItemSourceType;
}

interface BillingSectionParams {
  billingItems?: AssessmentBillingItemParams[];
  billingSectionNote?: string;
}

export const billingValuesAdapter = (values: BillingFormData | undefined): BillingSectionParams => ({
  billingItems: values?.assessmentBillingItems?.length
    ? values.assessmentBillingItems.map((item) => {
        const assessmentBillingItemParams: AssessmentBillingItemParams = {
          isExcluded: item.isExcluded,
          preAuthorization: item.preAuthorization,
          performerBillingItemId: item.performerBillingItemId,
          invoicePackageSnapshotId: item.invoicePackageSnapshotId,
          invoicePackageItemSnapshotId: item.invoicePackageItemSnapshotId,
          quantity: item.quantity,
          onInsurance: !!item.onInsurance,
          locationTemplate: item.locationTemplate,
          locationElements: item.locationElements,
          billingItemSourceType: item.billingItemSourceType?.value,
        };

        return assessmentBillingItemParams;
      })
    : [],
  billingSectionNote: values && values.billNote,
});
