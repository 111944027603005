import React, { useCallback } from 'react';
import toast from 'react-hot-toast';
import { Modal } from 'reactstrap';
import type { ErrorWithMessage, Patient } from 'app/types';
import { useAppDispatch, useAppSelector, useModalCleaner } from 'app/helpers';
import { FORMS_ADD_PATIENT } from 'app/shared';
import { useAddClinicPatientMutation, useAddPatientMutation } from 'app/services/patients/patients';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { formValuesAdapter } from 'app/features/patient/editPatientModal/helpers/formValuesAdapter';
import { formValidator } from 'app/features/patient/patientForm/formValidator';
import { useCheckRequiredPatientData } from 'app/features/patient/patientForm/helpers/useCheckRequiredPatientData';
import { useInitialValues } from 'app/features/patient/patientForm/helpers/useInitialValues';
import { PatientForm } from 'app/features/patient/patientForm/patientForm';
import { PatientFormData, ValidatorType } from 'app/features/patient/patientForm/types';

interface Props {
  initialPhoneNumber: string;
  clinicId?: string;
  onPatientCreated?: (data: Patient) => void;
}

export const AddPatientModal = ({ clinicId, initialPhoneNumber, onPatientCreated }: Props) => {
  const dispatch = useAppDispatch();

  // Selectors
  const isOpen = useAppSelector((state) => state.modal.addPatient.isOpen);

  // Requests
  const [addPatient, { isLoading: isAddPatientLoading }] = useAddPatientMutation();
  const [addClinicPatient, { isLoading: isAddClinicPatientLoading }] = useAddClinicPatientMutation();

  // Helpers
  const initialValues = useInitialValues(initialPhoneNumber);

  const { isNationalIdRequired, isGrandFatherNameRequired, isFatherNameRequired } = useCheckRequiredPatientData(
    isOpen,
    clinicId,
  );

  // Conditions
  const isRequesting = !!clinicId ? isAddClinicPatientLoading : isAddPatientLoading;

  // Handlers
  const onToggle = useCallback(() => {
    dispatch(toggleModal('addPatient'));
  }, [dispatch]);

  const onSubmit = useCallback(
    async (values: PatientFormData) => {
      try {
        const bodyParams = formValuesAdapter(values);
        let result;
        if (!!clinicId) {
          result = await addClinicPatient({ body: bodyParams, clinicId }).unwrap();
        } else {
          result = await addPatient({ body: bodyParams }).unwrap();
        }

        if (onPatientCreated) {
          onPatientCreated(result.data);
        }
        toast.success('CORE.TEXT.SUCCESSFULLY-ADDED');
        onToggle();
      } catch (error) {
        const queryError = error as ErrorWithMessage;

        if (queryError?.data?.message) {
          toast.error(queryError.data.message);
        } else {
          toast.error('CORE.TEXT.DEFAULT-FAILURE-MESSAGE');
        }
      }
    },
    [addClinicPatient, addPatient, clinicId, onToggle, onPatientCreated],
  );

  const validate = useCallback(
    (values: PatientFormData) => {
      const validatorType: ValidatorType = {
        isGrandFatherNameRequired,
        isFatherNameRequired,
        isNationalIdRequired,
      };

      return formValidator(values, validatorType);
    },
    [isGrandFatherNameRequired, isFatherNameRequired, isNationalIdRequired],
  );

  useModalCleaner('addPatient');

  return (
    <Modal backdrop="static" keyboard={false} isOpen={isOpen} size="xl" toggle={onToggle}>
      <PatientForm
        isDependent={false}
        clinicId={clinicId}
        form={FORMS_ADD_PATIENT}
        onSubmit={onSubmit}
        closeModal={onToggle}
        initialValues={initialValues}
        isRequesting={isRequesting}
        isEdit={false}
        validate={validate}
      />
    </Modal>
  );
};
