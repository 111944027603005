import { useMemo } from 'react';
import classnames from 'classnames';
import { useAppIntl, useAppSelector } from 'app/helpers';
import { DescriptionList, FormattedPrice } from 'app/components';
import { Item } from 'app/components/descriptionList/types';
import { useAccountCredit } from 'app/features/invoicingProcess/process/helpers/useAccountCredit';

interface Props {
  className?: string;
}
export const AccountBalance = ({ className }: Props) => {
  const { formatMessage } = useAppIntl();
  const { currentAccountCredit } = useAccountCredit();
  const totalDebtAmountDue = useAppSelector(
    (state) => state.invoicingProcess.data?.patientBalance.totalDebtAmountDue || 0,
  );

  const items = useMemo<Item[]>(() => {
    return [
      {
        label: formatMessage({ id: 'CORE.LABEL.AVAILABLE-ACCOUNT-CREDIT' }),
        value: (
          <span
            className={classnames('fw-medium', {
              'text-success': currentAccountCredit >= 0,
              'text-danger': currentAccountCredit < 0,
            })}
          >
            <FormattedPrice amount={currentAccountCredit} />
          </span>
        ),
      },
      {
        label: formatMessage({ id: 'ACCOUNT-BALANCE.TABLE.TOTAL-DEBT-AMOUNT-DUE' }),
        value: (
          <span className="fw-medium text-danger">
            <FormattedPrice amount={totalDebtAmountDue} />
          </span>
        ),
      },
    ];
  }, [currentAccountCredit, formatMessage, totalDebtAmountDue]);

  return (
    <div className={className}>
      <h5>{formatMessage({ id: 'ACCOUNT-BALANCE.BUTTON.PATIENT-ACCOUNT-BALANCE' })}</h5>
      <DescriptionList items={items} />
      <hr className="mt-0" />
    </div>
  );
};
