import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { Modal } from 'reactstrap';
import type { ErrorWithMessage } from 'app/types';
import { useAppDispatch, useAppSelector, useModalCleaner } from 'app/helpers';
import { FORMS_PATIENT_VERIFICATIONS } from 'app/shared';
import { useVerifyAndCheckInMutation } from 'app/services/appointments/appointments';
import { useLazyGetPatientPageDetailsQuery, useVerifyPatientProfileMutation } from 'app/services/patients/patients';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { RequireVerificationType, VerificationProcessData } from 'app/redux/patient/types';
import { formValuesAdapter } from 'app/features/patient/editPatientModal/helpers/formValuesAdapter';
import { useInitialValues } from 'app/features/patient/editPatientModal/helpers/useInitialValues';
import { useCheckRequiredPatientData } from 'app/features/patient/patientForm/helpers/useCheckRequiredPatientData';
import { PatientForm } from 'app/features/patient/patientForm/patientForm';
import { PatientFormData, ValidatorType } from 'app/features/patient/patientForm/types';
import { formValidator } from 'app/features/patient/patientVerificationModal/helpers/formValidator';

export const PatientVerificationModal = () => {
  const dispatch = useAppDispatch();

  // Selectors
  const isOpen = useAppSelector((state) => state.modal.patientVerification.isOpen);

  const verificationProcess: VerificationProcessData | null = useAppSelector(
    (state) => state.patientSlice.verificationProcess,
  );

  const [triggerGetPatientPageDetails, { isLoading, isFetching, data }] = useLazyGetPatientPageDetailsQuery();
  const [verifyAndCheckIn, { isLoading: isVerificationProcessLoading }] = useVerifyAndCheckInMutation();
  const [verifyPatientProfile, { isLoading: isPatientVerificationProcessLoading }] = useVerifyPatientProfileMutation();

  // Conditions
  const isFetchingPatientData = isLoading || isFetching;

  // Helper Hooks
  const initialValues = useInitialValues(!!verificationProcess?.clinicId, data?.data);

  const { isGrandFatherNameRequired, isFatherNameRequired } = useCheckRequiredPatientData(
    isOpen,
    verificationProcess?.clinicId,
  );

  // Handlers
  const onToggle = useCallback(() => {
    dispatch(toggleModal('patientVerification', false));
  }, [dispatch]);

  const onSubmit = useCallback(
    async (values: PatientFormData) => {
      if (verificationProcess)
        try {
          const bodyParams = formValuesAdapter(values);
          if (verificationProcess.actionType === RequireVerificationType.CheckIn && verificationProcess.appointmentId) {
            await verifyAndCheckIn({
              body: bodyParams,
              clinicId: verificationProcess.clinicId,
              clinicPatientId: verificationProcess.dependantId || verificationProcess.patientId,
              appointmentId: verificationProcess.appointmentId,
            }).unwrap();
            onToggle();
            toast.success('PATIENTS-ARRIVAL.TEXT.CHECK-IN-NOTIFICATION');
          } else {
            await verifyPatientProfile({
              body: bodyParams,
              clinicId: verificationProcess.clinicId,
              clinicPatientId: verificationProcess.patientId,
            }).unwrap();
            toast.success('PATIENTS.TEXT.REQUIRE-VERIFICATION-SUCCESS');
            onToggle();
          }
        } catch (error) {
          const queryError = error as ErrorWithMessage;

          if (queryError?.data?.message) {
            toast.error(queryError.data.message);
          } else {
            toast.error('CORE.TEXT.DEFAULT-FAILURE-MESSAGE');
          }
        }
    },
    [verifyAndCheckIn, onToggle, verificationProcess, verifyPatientProfile],
  );

  const onOpened = useCallback(() => {
    if (verificationProcess) {
      triggerGetPatientPageDetails({
        clinicId: verificationProcess.clinicId,
        patientId: verificationProcess.dependantId || verificationProcess.patientId,
      });
    }
  }, [triggerGetPatientPageDetails, verificationProcess]);

  useModalCleaner('patientVerification');

  const validate = useCallback(
    (values: PatientFormData) => {
      const validatorType: ValidatorType = {
        isGrandFatherNameRequired,
        isFatherNameRequired,
      };

      return formValidator(values, validatorType);
    },
    [isFatherNameRequired, isGrandFatherNameRequired],
  );

  return (
    <Modal onOpened={onOpened} backdrop="static" keyboard={false} isOpen={isOpen} size="xl" toggle={onToggle}>
      {verificationProcess && (
        <PatientForm
          clinicId={verificationProcess.clinicId}
          form={FORMS_PATIENT_VERIFICATIONS}
          onSubmit={onSubmit}
          closeModal={onToggle}
          isEdit={true}
          isRequesting={isPatientVerificationProcessLoading || isVerificationProcessLoading}
          isDependent={false}
          isFetchingPatientData={isFetchingPatientData}
          initialValues={initialValues}
          validate={validate}
          isVerify
        />
      )}
    </Modal>
  );
};
