import React, { useCallback } from 'react';
import toast from 'react-hot-toast';
import { Modal } from 'reactstrap';
import type { ErrorWithMessage, Patient } from 'app/types';
import { useAppDispatch, useAppSelector, useModalCleaner } from 'app/helpers';
import { FORMS_ADD_PATIENT_DEPENDENT } from 'app/shared';
import { useAddClinicPatientDependentMutation } from 'app/services/patients/patients';
import { toggleModal } from 'app/redux/modals/modals.actions';
import { formValuesAdapter } from 'app/features/patient/editPatientModal/helpers/formValuesAdapter';
import { formValidator } from 'app/features/patient/patientForm/formValidator';
import { useCheckRequiredPatientData } from 'app/features/patient/patientForm/helpers/useCheckRequiredPatientData';
import { useInitialValuesDependent } from 'app/features/patient/patientForm/helpers/useInitialValuesDependent';
import { PatientForm } from 'app/features/patient/patientForm/patientForm';
import { PatientFormData, ValidatorType } from 'app/features/patient/patientForm/types';
import { PreSearchDependentResult } from 'app/features/patient/preSearchClinicPatientModal/form/types';

interface Props {
  clinicId: string;
  primaryPatientData: PreSearchDependentResult;
  onDependentPatientCreated?: (data: Patient) => void;
}

export const AddPatientDependentModal = ({ clinicId, primaryPatientData, onDependentPatientCreated }: Props) => {
  const dispatch = useAppDispatch();

  // Selectors
  const isOpen = useAppSelector((state) => state.modal.addPatientDependent.isOpen);

  // Requests
  const [addDependent, { isLoading: isAddDependentLoading }] = useAddClinicPatientDependentMutation();

  // Helpers
  const initialValues = useInitialValuesDependent(primaryPatientData);

  const { isNationalIdRequired, isGrandFatherNameRequired, isFatherNameRequired } = useCheckRequiredPatientData(
    isOpen,
    clinicId,
  );

  // Handlers
  const onToggle = useCallback(() => {
    dispatch(toggleModal('addPatientDependent'));
  }, [dispatch]);

  const onSubmit = useCallback(
    async (values: PatientFormData) => {
      try {
        const bodyParams = formValuesAdapter(values);
        const result = await addDependent({ body: bodyParams, clinicId }).unwrap();

        if (onDependentPatientCreated) {
          onDependentPatientCreated(result.data);
        }
        toast.success('CORE.TEXT.SUCCESSFULLY-ADDED');
        onToggle();
      } catch (error) {
        const queryError = error as ErrorWithMessage;

        if (queryError?.data?.message) {
          toast.error(queryError.data.message);
        } else {
          toast.error('CORE.TEXT.DEFAULT-FAILURE-MESSAGE');
        }
      }
    },
    [addDependent, clinicId, onToggle, onDependentPatientCreated],
  );

  const validate = useCallback(
    (values: PatientFormData) => {
      const validatorType: ValidatorType = {
        isGrandFatherNameRequired,
        isFatherNameRequired,
        isNationalIdRequired,
      };

      return formValidator(values, validatorType);
    },
    [isGrandFatherNameRequired, isFatherNameRequired, isNationalIdRequired],
  );

  useModalCleaner('addPatientDependent');

  return (
    <Modal backdrop="static" keyboard={false} isOpen={isOpen} size="xl" toggle={onToggle}>
      <PatientForm
        isDependent={true}
        clinicId={clinicId}
        form={FORMS_ADD_PATIENT_DEPENDENT}
        onSubmit={onSubmit}
        closeModal={onToggle}
        initialValues={initialValues}
        isRequesting={isAddDependentLoading}
        isEdit={false}
        validate={validate}
      />
    </Modal>
  );
};
