import { useMemo } from 'react';
import round from 'lodash/round';
import sumBy from 'lodash/sumBy';
import { checkIsTemporaryId, useAppSelector } from 'app/helpers';
import { InvoicePaymentMethodItem, PatientPaymentMethod } from 'app/types';

interface AccountCreditFields {
  // Current value modified by the Invoicing Process
  currentAccountCredit: number;
  // Original value from the Patient Account Balance
  originalAccountCredit: number;
}

export const useAccountCredit = (): AccountCreditFields => {
  const paymentMethodItems = useAppSelector((state) => state.invoicingProcess.paymentMethodItems);
  const totalAccountCreditAmount = useAppSelector(
    (state) => state.invoicingProcess.data?.patientBalance.totalAccountCreditAmount,
  );
  const originalAccountCredit: number = totalAccountCreditAmount || 0;

  const currentAccountCredit = useMemo<number>(() => {
    const accountCreditPayments = paymentMethodItems.filter(
      // take only newly created payment methods
      (item) => item.paymentMethod.value === PatientPaymentMethod.FromAccountCredit && checkIsTemporaryId(item.id),
    );

    const accountCreditPaymentsTotal = round(
      sumBy<InvoicePaymentMethodItem>(accountCreditPayments, (item) => item.amount),
      2,
    );

    return round(originalAccountCredit - accountCreditPaymentsTotal, 2);
  }, [paymentMethodItems, originalAccountCredit]);

  return {
    currentAccountCredit,
    originalAccountCredit,
  };
};
