import { useMemo } from 'react';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';
import { Button, Col, InputGroupText, Row } from 'reactstrap';
import { RowProps } from 'reactstrap/types/lib/Row';
import { Field, Form, reduxForm } from 'redux-form';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { useAppIntl, useAppSelector, useDictionaries } from 'app/helpers';
import { SarCurrencySymbol } from 'app/components/sarCurrencySymbol/sarCurrencySymbol';
import { FORMS_INVOICING_PROCESS_EDIT_PAYMENT_METHOD, InputComponent, required, SelectComponent } from 'app/shared';
import { PatientPaymentMethod, Status } from 'app/types';
import { useAccountCredit } from 'app/features/invoicingProcess/process/helpers/useAccountCredit';
import { useLeftToPay } from 'app/features/invoicingProcess/process/paymentMethod/helpers/useLeftToPay';
import {
  PaymentMethodField,
  PaymentMethodFormData,
} from 'app/features/invoicingProcess/process/paymentMethod/paymentMethodForm/types';

const FormComponent = ({ form, handleSubmit, initialValues }: InjectedFormProps<PaymentMethodFormData>) => {
  const { formatMessage } = useAppIntl();
  const { 'patient-payment-methods': patientPaymentMethods } = useDictionaries();
  const isEditMode = form === FORMS_INVOICING_PROCESS_EDIT_PAYMENT_METHOD;
  const paymentMethodValue: PatientPaymentMethod | undefined = useAppSelector(
    (state) => state.form[form]?.values?.[PaymentMethodField.paymentMethod]?.value,
  );
  const updatePaymentsLoading = useAppSelector((state) => state.invoicingProcess.updatePaymentsLoading);
  const isOtherPaymentMethod = paymentMethodValue === PatientPaymentMethod.Other;
  const isFromAccountCreditPaymentMethod = paymentMethodValue === PatientPaymentMethod.FromAccountCredit;
  const rowProps: RowProps = isOtherPaymentMethod ? { xs: 1, lg: 2, xl: 3 } : { xs: 1, lg: 2, xl: 4 };

  // Limits
  const leftToPay = useLeftToPay();
  const { currentAccountCredit } = useAccountCredit();
  const initialAmountValue = Number(initialValues?.amount || 0);
  const initialPaymentMethod = initialValues?.paymentMethod?.value;
  const disabled = (!isEditMode && leftToPay <= 0) || updatePaymentsLoading;

  const maxAmount = useMemo(() => {
    const hasCurrentAccountCredit = currentAccountCredit > 0; // remember that currentAccountCredit can have a value less than 0.

    // 1. Edit mode
    if (isEditMode) {
      const limit = initialAmountValue + leftToPay;

      if (isFromAccountCreditPaymentMethod) {
        if (initialPaymentMethod === PatientPaymentMethod.FromAccountCredit) {
          // 1.1. We are editing payment with method FromAccountCredit
          if (hasCurrentAccountCredit) {
            return round(initialAmountValue + currentAccountCredit, 2);
          } else {
            return round(initialAmountValue, 2);
          }
        }

        // 1.2. We are editing a payment whose method was different from FromAccountCredit
        if (hasCurrentAccountCredit && currentAccountCredit < limit) {
          return round(currentAccountCredit, 2);
        }
      }

      return round(limit, 2);
    }

    // 2. Create Mode
    if (isFromAccountCreditPaymentMethod && hasCurrentAccountCredit && currentAccountCredit < leftToPay) {
      return round(currentAccountCredit, 2);
    }

    return round(leftToPay, 2);
  }, [
    currentAccountCredit,
    initialAmountValue,
    initialPaymentMethod,
    isEditMode,
    isFromAccountCreditPaymentMethod,
    leftToPay,
  ]);

  const paymentMethodOptions = useMemo(() => {
    // Take active payment methods
    const activePatientPaymentMethods = patientPaymentMethods?.filter((pm) => pm.details?.status === Status.Enabled);

    // "From Account Credit" method first
    const sortedMethods = sortBy(
      activePatientPaymentMethods,
      (o) => o.value !== PatientPaymentMethod.FromAccountCredit,
    );

    if (currentAccountCredit <= 0) {
      // Skip the "From Account Credit" method if credit is not available
      return sortedMethods.filter((o) => o.value !== PatientPaymentMethod.FromAccountCredit);
    }

    return sortedMethods;
  }, [currentAccountCredit, patientPaymentMethods]);

  return (
    <Form onSubmit={handleSubmit}>
      <Row {...rowProps}>
        <Col>
          <Field
            name={PaymentMethodField.paymentMethod}
            component={SelectComponent}
            disabled={disabled}
            label={formatMessage({ id: 'CORE.LABEL.PAYMENT-METHOD', defaultMessage: 'Payment Method' })}
            options={paymentMethodOptions}
            validate={[required]}
            isRequired
          />
        </Col>
        {isOtherPaymentMethod && (
          <Col>
            <Field
              name={PaymentMethodField.otherPaymentMethod}
              component={InputComponent}
              disabled={disabled}
              label={formatMessage({
                id: 'CORE.LABEL.OTHER-PAYMENT-METHOD',
                defaultMessage: 'Other Payment Method',
              })}
              type="text"
              validate={[required]}
              isRequired
            />
          </Col>
        )}
        <Col>
          <Field
            name={PaymentMethodField.amount}
            appendContent={
              <InputGroupText>
                <SarCurrencySymbol />
              </InputGroupText>
            }
            component={InputComponent}
            disabled={disabled}
            label={formatMessage({ id: 'CORE.LABEL.AMOUNT' })}
            min={0.01}
            max={maxAmount}
            step={0.01}
            type="number"
            validate={[required]}
            isRequired
          />
        </Col>
        <Col>
          <Field
            name={PaymentMethodField.notes}
            component={InputComponent}
            disabled={disabled}
            label={formatMessage({ id: 'CORE.LABEL.NOTES', defaultMessage: 'Notes' })}
            type="text"
          />
        </Col>
        <Col>
          <Button block className="btn mb-3 mt-0 mt-lg-4" color="primary-gradient" disabled={disabled} type="submit">
            {isEditMode ? formatMessage({ id: 'CORE.BUTTON.SAVE' }) : formatMessage({ id: 'CORE.BUTTON.ADD' })}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export const PaymentMethodForm = reduxForm<PaymentMethodFormData>({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(FormComponent);
